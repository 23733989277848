import { User, UserManager, UserManagerSettings } from "oidc-client-ts";
import { removeCookie } from "typescript-cookie";
import { notifications } from "@mantine/notifications";

export class AuthService {
  // define the User Manager
  private _userManager: UserManager;

  public _accessToken?: string;

  // define identity settings
  private idpSettings: UserManagerSettings = {
    authority: `${process.env.REACT_APP_AUTH}`,
    client_id: "reactwebclient",
    redirect_uri: `${process.env.REACT_APP_URL}/signin-callback`,
    scope: "openid securedapi",
    response_type: "code",
    response_mode: "query",
    post_logout_redirect_uri: `${process.env.REACT_APP_URL}/signout-callback`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${process.env.REACT_APP_URL}/silent.renew.html`,
  };

  constructor() {
    // Init user manager
    this._userManager = new UserManager(this.idpSettings);
    this._userManager.getUser().then((u) => {
      if (!u?.expired) {
        this._accessToken = u?.access_token;
      }
    });
  }

  public logout = () =>
    this.clearSession(() =>
      this._userManager.signoutRedirect().catch(() => window.location.reload())
    );

  public loginRedirect = () =>
    this._userManager
      .signinRedirect({ state: window.location.href })
      .catch(() =>
        notifications.show({
          message: "Unable to connect to server",
          color: "red",
        })
      );

  public isAuthenticated = (): boolean => {
    return this._accessToken !== undefined;
  };

  public signinCallback = () => {
    this._userManager
      .signinCallback()
      .then((u: User | void) => {
        if (u && u.state) window.location.replace(u.state as string);
        else window.location.replace("/dashboard");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  public signoutCallback = () => {
    this._userManager
      .signoutCallback()
      .then(() => {
        this.clearSession();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  private clearSession = (callback?: Function) => {
    sessionStorage.clear();
    localStorage.clear();
    ["idsrv.session", "session_model"].forEach((cookie) =>
      removeCookie(cookie)
    );

    if (callback) callback();
  };
}

const authService = new AuthService();

export default authService;
