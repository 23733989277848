import { Box, Button, Text, Title } from "@mantine/core";
import { JobFormSummaryProps } from "../../../../../models";
import { IconArrowLeft, IconDeviceFloppy } from "@tabler/icons-react";

const JobSummary = ({ model, navigateStep }: JobFormSummaryProps) => {
  return (
    <>
      <Title mb={48}>Summary</Title>
      {model.customerId !== undefined && (
        <Box w="100%" display="flex" sx={{ gap: 24 }} mb={24}>
          <Box className="flex-1">
            <Text weight={500} transform="uppercase" color="blue">
              Customer
            </Text>
            <Text>{model.customerId}</Text>
          </Box>
          <Box className="flex-1">
            <Text weight={500} transform="uppercase" color="blue">
              Customer Contact
            </Text>
            <Text>{model.customerContactId}</Text>
          </Box>
        </Box>
      )}
      <Box w="100%" display="flex" sx={{ gap: 24 }} mb={24}>
        <Box className="flex-1">
          <Text weight={500} transform="uppercase" color="blue">
            Job Type
          </Text>
          <Text>{!model.jobType?.length ? "None Selected" : ""}</Text>
        </Box>
      </Box>
      <Text weight={500} transform="uppercase" color="blue">
        Description
      </Text>
      <Text mb={24}>{model.description}</Text>
      <Box w="100%" display="flex" sx={{ gap: 24 }} mb={24}>
        <Box className="flex-1">
          <Text weight={500} transform="uppercase" color="blue">
            Start Date
          </Text>
          <Text>{model.start?.toDateString()}</Text>
        </Box>
        <Box className="flex-1">
          <Text weight={500} transform="uppercase" color="blue">
            End Date
          </Text>
          <Text>{model.end?.toDateString()}</Text>
        </Box>
        <Box className="flex-1">
          <Text weight={500} transform="uppercase" color="blue">
            Start Time
          </Text>
          <Text mb={24}>{model.start?.toLocaleTimeString()}</Text>
        </Box>
      </Box>

      <Text weight={500} transform="uppercase" color="blue">
        Address
      </Text>
      <Text mb={48}>{model.address}</Text>

      <Box className="text-right">
        <Button
          variant="outline"
          leftIcon={<IconArrowLeft />}
          mr={24}
          onClick={() => navigateStep && navigateStep(-1)}
        >
          GO BACK
        </Button>
        <Button color="blue" leftIcon={<IconDeviceFloppy />} type="submit">
          CREATE JOB
        </Button>
      </Box>
    </>
  );
};

export default JobSummary;
