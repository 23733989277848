import { notifications } from "@mantine/notifications";
import { Result } from "../models";

export const parseResultErrors = (result: Result) => {
  if (!result.isValid) {
    if (result.errors?.length) {
      result.errors.forEach((error: string) => {
        notifications.show({
          message: error,
          color: "red",
        });
      });
    } else {
      notifications.show({
        message: result.error ?? "Something went wrong",
        color: "red",
      });
    }
  }
};
