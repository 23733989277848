import { lazy } from "react";

const steps: Array<{
  label: string;
  component: React.LazyExoticComponent<any>;
}> = [
  {
    label: "Job Info",
    component: lazy(
      async () =>
        import(
          "../../components/_molecules/jobs/job-form-stepper/steps/JobDetails"
        )
    ),
  },
  {
    label: "Time & Location",
    component: lazy(
      async () =>
        import(
          "../../components/_molecules/jobs/job-form-stepper/steps/Location"
        )
    ),
  },
  {
    label: "Customer Info",
    component: lazy(
      async () =>
        import(
          "../../components/_molecules/jobs/job-form-stepper/steps/Customer"
        )
    ),
  },
  {
    label: "Equipment & Operators",
    component: lazy(
      async () =>
        import(
          "../../components/_molecules/jobs/job-form-stepper/steps/Equipment"
        )
    ),
  },
];

export default steps;
