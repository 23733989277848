import { useState } from "react";
import { TextInput, TextInputProps, createStyles, rem } from "@mantine/core";

const useStyles = createStyles(
  (theme, { floating, hasIcon }: { floating: boolean; hasIcon: boolean }) => ({
    root: {
      position: "relative",
    },

    label: {
      position: "absolute",
      zIndex: 2,
      top: rem(10),
      left: !floating && hasIcon ? 36 : theme.spacing.sm,
      pointerEvents: "none",
      color: floating
        ? theme.colorScheme === "dark"
          ? theme.white
          : theme.black
        : theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
      transition:
        "transform 150ms ease, color 150ms ease, font-size 150ms ease",
      transform: floating
        ? `translate(-${theme.spacing.sm}, ${rem(-28)})`
        : "none",
      fontSize: floating ? theme.fontSizes.xs : theme.fontSizes.sm,
      fontWeight: floating ? 500 : 400,
    },

    required: {
      transition: "opacity 150ms ease",
      opacity: floating ? 1 : 0,
    },

    input: {
      "&::placeholder": {
        transition: "color 150ms ease",
        color: !floating ? "transparent" : undefined,
      },
    },
  })
);

const Input = (props: TextInputProps) => {
  const [focused, setFocused] = useState(false);
  const { classes } = useStyles({
    floating: props.value !== undefined || focused,
    hasIcon: !!props.icon,
  });

  return (
    <TextInput
      {...props}
      classNames={classes}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};

export default Input;
