import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./const";
import { AuthConsumer } from "./services";
import { SigninCallback, SignoutCallback } from "../pages";
import { Suspense } from "react";
import { Loader, PageLoader } from "./components";
import { Box } from "@mantine/core";

const AuthenticatedRoute = ({ Component }: any) => {
  return (
    <AuthConsumer>
      {({ isAuthenticated, loginRedirect }) => {
        if (isAuthenticated()) {
          return <Component />;
        }
        loginRedirect();
        return <PageLoader />;
      }}
    </AuthConsumer>
  );
};

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/signin-callback" element={<SigninCallback />} />
      <Route path="/signout-callback" element={<SignoutCallback />} />
      {routes
        .filter((x) => x.path?.length)
        .map((x, i) => (
          <Route
            key={i}
            path={x.path}
            element={
              <Suspense
                fallback={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Loader />
                  </Box>
                }
              >
                <AuthenticatedRoute Component={x.component} />
              </Suspense>
            }
          />
        ))}
    </Routes>
  );
};

export default Router;
