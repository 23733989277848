import { AuthConsumer } from "../../lib";

const SigninCallback = () => {
  return (
    <AuthConsumer>
      {({ signinCallback }) => {
        signinCallback();
        return <></>;
      }}
    </AuthConsumer>
  );
};

export default SigninCallback;
