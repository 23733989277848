import {
  Box,
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  MantineThemeOverride,
} from "@mantine/core";
import { ContainerModel } from "../../../models";
import Sidebar from "../sidebar/Sidebar";
import { useState } from "react";
import { getCookie, setCookie } from "typescript-cookie";
import { Notifications } from "@mantine/notifications";

const Container = ({ children }: ContainerModel) => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    (getCookie("colorScheme") as ColorScheme) ?? "light"
  );
  const toggleColorScheme = (value?: ColorScheme) => {
    const theme = value || (colorScheme === "dark" ? "light" : "dark");
    setColorScheme(theme);
    setCookie("colorScheme", theme);
  };

  const theme: MantineThemeOverride = { colorScheme, black: "#0e2a39" };

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
        <Sidebar />
        <Box className={`container ${colorScheme}`}>{children}</Box>
        <Notifications />
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default Container;
