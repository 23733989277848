import { notifications } from "@mantine/notifications";

class AddressService {
  get = async (searchText: string) => {
    try {
      const url = `https://addressr.p.rapidapi.com/addresses?q=${encodeURIComponent(
        searchText
      )}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "X-RapidAPI-Key":
            "fa4a413ca8msha336b3ecf93d6edp16425ajsn29cd575afe0e",
          "X-RapidAPI-Host": "addressr.p.rapidapi.com",
        },
      });

      const result = response.ok ? await response.json() : [];

      return result?.map((z: any) => z.sla);
    } catch {
      notifications.show({
        message: "There was an error",
        color: "red",
      });
      return undefined;
    }
  };
}

const addressService = new AddressService();

export default addressService;
