export default class Date extends window.Date {
  // GETS

  public getDateString = (): string => {
    const dateString = this.toDateString();
    return dateString.substring(3, dateString.length);
  };
  public getTimeString = (): string => {
    const time = this.toLocaleTimeString();
    const timeArray = time.split(":");
    return `${timeArray[0]}:${timeArray[1]} ${timeArray[2]?.split(" ")[1]}`;
  };

  public getStartOfWeekDate = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysToSubtract = dayOfWeek === 0 ? 7 : dayOfWeek; // 0 represents Sunday, so if today is Sunday, subtract 7 days

    return today.getDate() - daysToSubtract;
  };

  public getEndOfWeekDate = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysToAdd = 6 - dayOfWeek;

    return today.getDate() + daysToAdd;
  };

  public getEndOfMonthDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    return new Date(year, month + 1, 0).getDate();
  };

  // SETS

  public addDateDays = (days: number): Date => {
    return new Date(this.setDate(this.getDate() + days));
  };

  public setDateDate = (date: number): Date => {
    return new Date(new Date().setDate(date));
  };

  public setDateHours = (hours: number): Date => {
    return new Date(this.setHours(hours));
  };

  public setDateMinutes = (mins: number): Date => {
    return new Date(this.setMinutes(mins, 0, 0));
  };
}
