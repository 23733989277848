import { Box, Loader as Loading, Sx, Text } from "@mantine/core";

interface LoaderProps {
  text?: string;
  sx?: Sx;
}

const Loader = ({ text, sx }: LoaderProps) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        maxWidth: 400,
        width: "100%",
        flex: 1,
        margin: "0 auto",
        ...sx,
      }}
    >
      <Loading mb={12} />
      <Text weight={500} size="sm" sx={{ lineHeight: 1 }}>
        {text ?? "LOADING"}
      </Text>
    </Box>
  );
};

export default Loader;
