import { Box, Sx, Text } from "@mantine/core";
import { AvatarSizes, Sizes } from "../../../models";

interface AvatarProps {
  initials: string;
  size?: Sizes;
  sx?: Sx;
}

const Avatar = ({ initials, size, sx }: AvatarProps) => {
  return (
    <Box
      sx={{
        background: "#228AE6",
        width: size ? AvatarSizes[size] : AvatarSizes[Sizes.md],
        height: size ? AvatarSizes[size] : AvatarSizes[Sizes.md],
        borderRadius: 50,
        color: "#fff",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Text
        sx={{
          lineHeight: 1,
          fontSize: (size ? AvatarSizes[size] : AvatarSizes[Sizes.md]) / 2 - 2,
          fontWeight: 600,
        }}
      >
        {initials}
      </Text>
    </Box>
  );
};

export default Avatar;
