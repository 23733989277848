import { ApiSearchParams } from "../../models";
import authService from "../auth/auth.service";

class Http {
  public static timeout = 10000; // 30 second timeout

  public static defaultPage = 1;
  public static defaultPageSize = 25;

  private static formatUrlQueryParams = (
    path: string,
    params?: ApiSearchParams
  ) => {
    if (params) {
      const keys = Object.keys(params);
      const values = Object.values(params);

      if (keys?.length) {
        path += `?${keys
          .filter((x, i) => !!values[i])
          .map((key, i) => {
            const value = values[i];

            if (value instanceof Date) return `${key}=${value.toISOString()}`;

            return `${key}=${encodeURIComponent(value)}`;
          })
          .join("&")}`;
      }
    }

    return path;
  };

  private static getHeaders = () => {
    let headers: any = {
      "Content-Type": "application/json",
    };

    const accessToken = authService._accessToken;
    if (accessToken?.length) headers.Authorization = `Bearer ${accessToken}`;

    return new Headers(headers);
  };

  private static createRequest = async <T>(
    path: string,
    method: string,
    body?: string
  ) => {
    // Get auth headings
    const headers = this.getHeaders();

    // init abort controller
    const controller = new AbortController();
    const signal = controller.signal;

    const timer = setTimeout(() => controller.abort(), this.timeout);

    path = path.startsWith("http")
      ? path
      : `${process.env.REACT_APP_API}/${path}`;
    return await fetch(path, {
      method,
      body,
      signal: signal,
      headers,
    })
      .then((res) => {
        //if (res.status === 401) authService.logout();
        clearTimeout(timer);
        controller.signal.removeEventListener("abort", controller.abort);
        return res.json() as T;
      })
      .catch((e) => {
        clearTimeout(timer);
        controller.signal.removeEventListener("abort", controller.abort);
        throw e;
      });
  };

  public static get = async <T>(
    path: string,
    params?: ApiSearchParams
  ): Promise<T> =>
    await this.createRequest(this.formatUrlQueryParams(path, params), "GET");

  public static post = async <T>(path: string, body: object): Promise<T> =>
    await this.createRequest(path, "POST", JSON.stringify(body));

  public static put = async <T>(path: string, body: object): Promise<T> =>
    await this.createRequest(path, "PUT", JSON.stringify(body));

  public static delete = async <T>(path: string): Promise<T> =>
    await this.createRequest(path, "DELETE");
}

export default Http;
