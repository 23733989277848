import { createContext, useContext, useState } from "react";
import authService from "./auth.service";
import { AuthContextModel, ResultModel, UserModel } from "../../models";
import { getCookie, setCookie } from "typescript-cookie";
import Http from "../http/http.service";

const userCookie = getCookie("session_model");

const authContextModel: AuthContextModel = {
  user: userCookie ? JSON.parse(userCookie) : undefined,
  isAuthenticated: authService.isAuthenticated,
  loginRedirect: authService.loginRedirect,
  logout: authService.logout,
  signinCallback: authService.signinCallback,
  signoutCallback: authService.signoutCallback,
};

const AuthContext = createContext<AuthContextModel>(authContextModel);

const AuthProvider = ({ children }: any) => {
  const [data, setData] = useState(authContextModel);
  const [loading, setLoading] = useState(false);

  if (!loading && !data.user && authContextModel.isAuthenticated()) {
    setLoading(true);

    Http.get<ResultModel<UserModel>>("session")
      .then((x) => {
        if (x.isValid && x.model) {
          setCookie("session_model", JSON.stringify(x.model), {
            expires: x.model.expires ? new Date(x.model.expires) : undefined,
          });
          setData({
            ...data,
            user: x.model,
          });
          setLoading(false);
        }
      })
      .catch((x) => {
        setData({
          ...data,
          user: {
            firstName: "Fetching user...",
            lastName: "",
            email: "",
          },
        });
      });
  }

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider, AuthConsumer };
