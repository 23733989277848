import { createStyles, rem, Select, SelectProps } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
  },

  input: {
    height: rem(54),
    paddingTop: rem(18),
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

const SelectOutlined = (props: SelectProps) => {
  const { classes } = useStyles();
  return (
    <Select
      withinPortal
      {...props}
      data={
        !props.data.length
          ? [{ value: "", label: `No options available`, disabled: true }]
          : [
              { value: "", label: "-- Choose an Option --", disabled: true },
              ...props.data,
            ]
      }
      classNames={classes}
    />
  );
};

export default SelectOutlined;
