import { lazy } from "react";
import { AppRoute } from "../models";
import {
  IconBoxSeam,
  IconBriefcase,
  IconBuildingBank,
  IconBuildingSkyscraper,
  IconForklift,
  IconHome2,
  IconReceipt,
  IconUsersGroup,
} from "@tabler/icons-react";

// These are all authenticated routes only
const routes: Array<AppRoute> = [
  {
    title: "Dashboard",
    path: "/dashboard",
    component: lazy<any>(() => import("../../pages/dashboard/Dashboard")),
    icon: IconHome2,
  },
  {
    title: "Jobs",
    path: "/jobs",
    component: lazy<any>(() => import("../../pages/jobs/ListJobs")),
    icon: IconBriefcase,
  },
  {
    path: "/jobs/new",
    component: lazy<any>(() => import("../../pages/jobs/CreateJob")),
    hideInMenu: true,
  },
  {
    path: "/jobs/:id",
    component: lazy<any>(() => import("../../pages/jobs/ViewJob")),
    hideInMenu: true,
  },
  {
    title: "Dockets",
    path: "/dockets",
    component: lazy<any>(() => import("../../pages/dockets/Dockets")),
    icon: IconReceipt,
  },
  {
    title: "Operators",
    path: "/operators",
    component: lazy<any>(() => import("../../pages/operators/Operators")),
    icon: IconUsersGroup,
  },
  {
    title: "Equipment",
    path: "/equipment",
    component: lazy<any>(() => import("../../pages/equipment/Equipment")),
    icon: IconForklift,
  },
  {
    title: "Customers",
    path: "/customers",
    component: lazy<any>(() => import("../../pages/customers/Customers")),
    icon: IconBuildingSkyscraper,
  },
  {
    title: "Suppliers",
    path: "/suppliers",
    component: lazy<any>(() => import("../../pages/suppliers/Suppliers")),
    icon: IconBoxSeam,
  },
  {
    title: "Compliance",
    path: "/compliance",
    component: lazy<any>(() => import("../../pages/compliance/Compliance")),
    icon: IconBuildingBank,
  },

  // Non Visible Routes
  {
    path: "user-profile",
    component: lazy<any>(() => import("../../pages/user-profile/UserProfile")),
  },
  {
    path: "*",
    component: lazy<any>(() => import("../../pages/errors/404")),
  },
];

export default routes;
