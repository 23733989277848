import { DefaultMantineColor } from "@mantine/core";
import { JobStatus } from "../../enums";

const JobColors: { [key in JobStatus]?: DefaultMantineColor } = {
  [JobStatus.Draft]: "gray",
  [JobStatus.Created]: "blue",
  [JobStatus.Accepted]: "grape",
  [JobStatus.Started]: "orange",
  [JobStatus.Complete]: "green",
  [JobStatus.Cancelled]: "red",
  [JobStatus.Archived]: "dark",
};

export default JobColors;
