import { notifications } from "@mantine/notifications";
import {
  ApiSearchParams,
  JobDetailDto,
  JobListDto,
  Result,
  ResultListing,
  ResultModel,
} from "../../models";
import Http from "../http/http.service";
import { parseResultErrors } from "../../helpers";

class JobService {
  get = async (searchParams?: ApiSearchParams) => {
    try {
      const result = await Http.get<ResultListing<JobListDto>>(
        "jobs",
        searchParams
      );

      parseResultErrors(result);

      return result;
    } catch {
      notifications.show({
        message: "Could not fetch jobs",
        color: "red",
      });
      return undefined;
    }
  };

  getById = async (id: string) => {
    try {
      const result = await Http.get<ResultModel<JobDetailDto>>(`jobs/${id}`);

      parseResultErrors(result);

      return result;
    } catch {
      notifications.show({
        message: "Could not fetch job",
        color: "red",
      });
      return undefined;
    }
  };

  create = async (model: JobDetailDto) => {
    try {
      const result = await Http.post<Result>(`jobs`, {
        ...model,
        start: model.start?.toISOString(),
        end: model.end?.toISOString(),
      });

      parseResultErrors(result);

      return result;
    } catch {
      notifications.show({
        message: "Could not create job",
        color: "red",
      });
      return undefined;
    }
  };
}

const jobService = new JobService();

export default jobService;
