import { createRef } from "react";
import { ActionIcon, createStyles, rem } from "@mantine/core";
import { TimeInput, TimeInputProps } from "@mantine/dates";
import { IconClock } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
  },

  input: {
    height: rem(54),
    paddingTop: rem(18),
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

const TimeInputOutlined = (props?: TimeInputProps) => {
  const ref = createRef<HTMLInputElement>();
  const { classes } = useStyles();

  return (
    <TimeInput
      ref={ref}
      {...props}
      classNames={classes}
      onClick={() => ref.current?.showPicker()}
      rightSection={
        <ActionIcon onClick={() => ref.current?.showPicker()}>
          <IconClock size="20" />
        </ActionIcon>
      }
    />
  );
};

export default TimeInputOutlined;
