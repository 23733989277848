import {
  ActionIcon,
  Button,
  Group,
  useMantineColorScheme,
} from "@mantine/core";
import { PageTitleModel } from "../../../models";
import { IconArrowLeft } from "@tabler/icons-react";

const PageTitle = ({ children, actions, showGoBack }: PageTitleModel) => {
  const colorScheme = useMantineColorScheme()?.colorScheme;
  return (
    <Group pb="xl">
      {showGoBack && (
        <>
          <ActionIcon
            variant="subtle"
            radius="lg"
            size="lg"
            color={colorScheme === "light" ? "dark" : "gray"}
            onClick={() => window.history.back()}
          >
            <IconArrowLeft size={18} />
          </ActionIcon>
        </>
      )}
      <h1 className="flex-1">{children}</h1>
      {actions?.length && (
        <>
          {actions.map((a, i) =>
            a.title ? (
              <Button
                key={"page-action-" + i}
                leftIcon={a.icon}
                onClick={() => a.onClick()}
                color={a.color}
                variant={a.variant}
              >
                {a.title}
              </Button>
            ) : (
              <></>
            )
          )}
        </>
      )}
    </Group>
  );
};

export default PageTitle;
