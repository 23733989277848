enum JobStatus {
  Draft,
  Created,
  Accepted,
  Started,
  Complete,
  Cancelled,
  Archived,
}

export default JobStatus;
