import { createStyles, rem } from "@mantine/core";
import { DatePickerInput, DatePickerInputProps } from "@mantine/dates";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
  },

  input: {
    height: rem(54),
    paddingTop: rem(18),
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

interface DateInputOutlinedProps extends DatePickerInputProps {
  placeholder?: string;
}

const DateInputOutlined = (props: DateInputOutlinedProps) => {
  const { classes } = useStyles();
  return (
    <DatePickerInput
      {...props}
      valueFormat="DD MMM YYYY"
      classNames={classes}
    />
  );
};

export default DateInputOutlined;
