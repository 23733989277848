import { ReactNode, useMemo, useState } from "react";
import { JobStatus } from "../../../../enums";
import { JobKanbanProps, KanbanColumnProps } from "../../../../models";
import JobKanbanFilters from "./JobKanbanFilters";
import { Kanban } from "../../../_atoms";
import JobCard from "../job-card/JobCard";
import { getCookie, setCookie } from "typescript-cookie";
import { useNavigate } from "react-router-dom";

const JobKanban = ({ data, filters, setFilters }: JobKanbanProps) => {
  // navigate
  const navigate = useNavigate();

  // get column config cache
  const columnFilterCookie = useMemo<{ [column: string]: boolean }>(() => {
    const cookie = getCookie("hidden_job_kanban_status_filters");
    if (cookie) return JSON.parse(cookie);
    return {};
  }, []);

  // set column config cache
  const setColumnCookie = (columns: Array<KanbanColumnProps<JobStatus>>) => {
    let cookieModel: { [column: string]: boolean } = {};
    columns.forEach((col) => (cookieModel[col.label] = !!col.hidden));
    setCookie("hidden_job_kanban_status_filters", JSON.stringify(cookieModel));
  };

  // kanban columns
  const [columns, setColumns] = useState<Array<KanbanColumnProps<JobStatus>>>([
    {
      label: JobStatus[JobStatus.Draft],
      value: JobStatus.Draft,
      hidden: columnFilterCookie[JobStatus[JobStatus.Draft]],
    },
    {
      label: JobStatus[JobStatus.Created],
      value: JobStatus.Created,
      hidden: columnFilterCookie[JobStatus[JobStatus.Created]],
    },
    {
      label: JobStatus[JobStatus.Accepted],
      value: JobStatus.Accepted,
      hidden: columnFilterCookie[JobStatus[JobStatus.Accepted]],
    },
    {
      label: JobStatus[JobStatus.Started],
      value: JobStatus.Started,
      hidden: columnFilterCookie[JobStatus[JobStatus.Started]],
    },
    {
      label: JobStatus[JobStatus.Complete],
      value: JobStatus.Complete,
      hidden: columnFilterCookie[JobStatus[JobStatus.Complete]] ?? true,
    },
    {
      label: JobStatus[JobStatus.Cancelled],
      value: JobStatus.Cancelled,
      hidden: columnFilterCookie[JobStatus[JobStatus.Cancelled]] ?? true,
    },
    {
      label: JobStatus[JobStatus.Archived],
      value: JobStatus.Archived,
      hidden: columnFilterCookie[JobStatus[JobStatus.Archived]] ?? true,
    },
  ]);

  // kanban data
  const kanbanData = useMemo<{ [column: string]: Array<ReactNode> }>(() => {
    // filter visible cols
    const visibleColumns = columns.filter((col) => !col.hidden);
    if (visibleColumns.length) {
      // init object
      let columnData: { [column: string]: Array<ReactNode> } = {};
      // loop through the visible columns
      visibleColumns.forEach((col) => {
        // get the data that matches the column
        if (data.results)
          columnData[col.value] = data.results
            .filter((x) => x.status === col.value)
            .map((j, ji) => (
              <JobCard
                key={`job_card_${col.value}_${ji}`}
                model={j}
                onClick={navigate}
              />
            ));
      });
      // return
      return columnData;
    }

    return {};
  }, [columns, data.results, navigate]);

  return (
    <>
      <JobKanbanFilters
        columns={columns}
        setColumns={(e) => {
          setColumns(e);
          setColumnCookie(e);
        }}
        filters={filters}
        setFilters={setFilters}
      />
      <Kanban columns={columns} data={kanbanData} />
    </>
  );
};

export default JobKanban;
