import {
  Button,
  Menu,
  ActionIcon,
  rem,
  createStyles,
  Box,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";

interface ActionButtonProps {
  children: React.ReactNode;
}

const useStyles = createStyles((theme) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
}));

const ActionButton = ({ children }: ActionButtonProps) => {
  const { classes, theme } = useStyles();

  return (
    <Box display="inline-flex">
      <Button className={classes.button} type="submit">
        Create Job
      </Button>
      <Menu
        transitionProps={{ transition: "pop" }}
        position="bottom-end"
        withinPortal
      >
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            size={36}
            className={classes.menuControl}
            type="button"
          >
            <IconChevronDown size="1rem" stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>{children}</Menu.Dropdown>
      </Menu>
    </Box>
  );
};

export default ActionButton;
