import { Box, Card, Text } from "@mantine/core";
import { KanbanProps } from "../../../models";

const Kanban = ({ data, columns, sx }: KanbanProps) => {
  columns = columns.filter((x) => !x.hidden);
  return (
    <>
      <Box>
        <Card display="flex" sx={{ gap: 12, ...sx }} mb={12} w="100%">
          {columns.map((col, i) => (
            <Text
              key={`${col.label} ${i}`}
              className="flex-1"
              weight={600}
              size="xs"
              display="block"
            >
              {col.label.toUpperCase()}
            </Text>
          ))}
        </Card>
      </Box>

      <Box display="flex" sx={{ gap: 12, ...sx }}>
        {columns.map((c, ci) => (
          <Box key={`${c.label} cardCOl ${ci}`} className="flex-1">
            {data !== undefined && data[c.value]?.map((z) => z)}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Kanban;
