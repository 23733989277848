import { BrowserRouter } from "react-router-dom";
import { AuthProvider, Container, Router } from "./lib";

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter
        children={
          <Container>
            <Router />
          </Container>
        }
        basename="/"
      />
    </AuthProvider>
  );
};

export default App;
