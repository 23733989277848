import { Box, Button, Step, Stepper } from "@mantine/core";
import { useState } from "react";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import JobSummary from "./steps/Summary";
import { steps } from "../../../../const";
import { JobFormProps } from "../../../../models";

const JobForm = ({
  loading,
  model,
  onModelChange,
  validateForm,
}: JobFormProps) => {
  const [step, setStep] = useState(0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Stepper
        active={step}
        size="sm"
        mb={24}
        display="flex"
        className="flex-1"
        sx={{
          flexDirection: "column",
        }}
      >
        {steps.map((x, i) => {
          const { component: Comp } = x as any;
          return (
            <Step step={i} key={i} label={x.label}>
              <Comp model={model} onModelChange={onModelChange} />
            </Step>
          );
        })}
        <Stepper.Completed>
          {!loading && (
            <JobSummary
              model={model}
              navigateStep={(index: number) => setStep(step + index)}
            />
          )}
        </Stepper.Completed>
      </Stepper>
      {step !== steps.length && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="outline"
            disabled={step === 0 || loading}
            onClick={() => setStep(step - 1)}
            leftIcon={<IconArrowLeft />}
            mr={24}
          >
            PREV
          </Button>
          <Button
            variant="outline"
            disabled={step === steps.length || loading}
            onClick={() => (validateForm() ? setStep(step + 1) : null)}
            rightIcon={<IconArrowRight />}
            color="green"
          >
            NEXT
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default JobForm;
