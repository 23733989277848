import { Text, TextProps } from "@mantine/core";

interface BadgeProps extends TextProps {}

const Badge = (props: BadgeProps) => {
  return (
    <Text
      weight={500}
      transform="uppercase"
      color="#fff"
      px={9}
      py={4}
      display="inline-block"
      sx={{
        borderRadius: 999,
        letterSpacing: "0.02rem",
        fontSize: "12px",
        lineHeight: "12px",
      }}
      {...props}
    >
      {props.children}
    </Text>
  );
};

export default Badge;
