import { useState } from "react";
import { Date } from "../../../../helpers";
import { useDisclosure } from "@mantine/hooks";
import { DatePicker } from "@mantine/dates";
import {
  Box,
  Button,
  Checkbox,
  Menu,
  Modal,
  useMantineColorScheme,
} from "@mantine/core";
import { Input } from "../../../_atoms";
import {
  IconArrowUp,
  IconCalendarCog,
  IconChevronDown,
  IconSearch,
} from "@tabler/icons-react";
import { JobKanbanFilterProps } from "../../../../models";

const JobKanbanFilters = ({
  filters,
  columns,
  setColumns,
  setFilters,
}: JobKanbanFilterProps) => {
  const [dateRange, setDateRange] = useState<Array<Date | null>>([
    filters.startDate ?? new Date(),
    filters.endDate ?? new Date().addDateDays(7),
  ]);
  const [datePickerOpened, { open, close }] = useDisclosure(false);
  const updateDateRange = (start: Date, end: Date | null) => {
    setDateRange([start, end]);
    if (!!end)
      setFilters({
        ...filters,
        startDate: start,
        endDate: end,
      });
  };

  const [search, setSearch] = useState(filters.search);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  const colorScheme = useMantineColorScheme()?.colorScheme;

  return (
    <>
      <Box mb={24} display="flex" sx={{ alignItems: "center" }}>
        {/* Date Range Button */}
        <Button
          color="blue"
          variant="outline"
          leftIcon={<IconCalendarCog size={16} />}
          onClick={open}
          size="xs"
        >
          {`${dateRange[0]?.getDateString()} - ${
            dateRange[1]?.getDateString() ?? ""
          }`.toUpperCase()}
        </Button>

        {/* Status Button */}
        <Menu closeOnItemClick={false}>
          <Menu.Target>
            <Button
              color={colorScheme === "light" ? "dark" : "gray"}
              variant="outline"
              rightIcon={<IconChevronDown size={14} />}
              size="xs"
              ml={12}
            >
              COLUMNS
            </Button>
          </Menu.Target>
          <Menu.Dropdown miw={180}>
            <Menu.Label>Show/Hide Columns</Menu.Label>
            {columns.map((col, i) => (
              <Menu.Item
                key={i}
                onClick={() =>
                  setColumns(
                    columns.map((c) => {
                      if (c.value === col.value) col.hidden = !col.hidden;
                      return c;
                    })
                  )
                }
              >
                <Checkbox
                  checked={!col.hidden}
                  onChange={() => {}}
                  label={col.label}
                  sx={{
                    pointerEvents: "none",
                  }}
                />
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>

        {/* Sorting Button */}
        <Menu closeOnItemClick={false}>
          <Menu.Target>
            <Button
              color={colorScheme === "light" ? "dark" : "gray"}
              variant="outline"
              rightIcon={<IconChevronDown size={14} />}
              size="xs"
              ml={12}
            >
              SORT BY
            </Button>
          </Menu.Target>
          <Menu.Dropdown miw={180}>
            <Menu.Label display="flex" sx={{ alignItems: "center" }}>
              Sort:
              <span className="flex-1"></span>
              <IconArrowUp size={14} /> ASC
            </Menu.Label>
            <Menu.Item>Date</Menu.Item>
            <Menu.Item>Start Time</Menu.Item>
            <Menu.Item>Address</Menu.Item>
            <Menu.Item>Customer</Menu.Item>
          </Menu.Dropdown>
        </Menu>

        {/* Seperator */}
        <span className="flex-1"></span>

        {/* Search */}
        <Input
          type="text"
          name="search"
          id="search"
          label="Search Jobs"
          placeholder="Start typing to search..."
          maw={400}
          miw={200}
          className="flex-1"
          value={search}
          icon={<IconSearch />}
          onChange={(e) => {
            setSearch(e.target.value);
            if (searchTimeout) clearTimeout(searchTimeout);
            setSearchTimeout(
              setTimeout(() => {
                setFilters({ ...filters, search: e.target.value });
              }, 500)
            );
          }}
        />
      </Box>

      {/* Date Range Modal */}
      <Modal
        opened={datePickerOpened}
        onClose={close}
        withCloseButton={false}
        size="auto"
        centered
      >
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            updateDateRange(
              new Date().setDateHours(0).setDateMinutes(0),
              new Date().setDateHours(11).setDateMinutes(59)
            );
          }}
        >
          Today
        </Button>
        &nbsp;
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            const start = new Date().getStartOfWeekDate();
            const end = new Date().getEndOfWeekDate();

            updateDateRange(
              new Date().setDateDate(start),
              new Date().setDateDate(end)
            );
          }}
        >
          This week
        </Button>
        &nbsp;
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            const start = new Date().setDateDate(1);
            const end = new Date().getEndOfMonthDate();

            updateDateRange(start, new Date().setDateDate(end));
          }}
        >
          This Month
        </Button>
        <DatePicker
          type="range"
          mt={12}
          numberOfColumns={2}
          columnsToScroll={1}
          firstDayOfWeek={0}
          value={dateRange as any}
          onChange={(e: any) => {
            updateDateRange(
              new Date(e[0]).setDateHours(0).setDateMinutes(0),
              e[1] ? new Date(e[1]).setDateHours(11).setDateMinutes(59) : null
            );
          }}
        />
      </Modal>
    </>
  );
};

export default JobKanbanFilters;
