import { AuthConsumer } from "../../lib";

const SignoutCallback = () => {
  return (
    <AuthConsumer>
      {({ signoutCallback }) => {
        signoutCallback();
        return <></>;
      }}
    </AuthConsumer>
  );
};

export default SignoutCallback;
