import { TextInput, TextInputProps, createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
  },

  input: {
    height: rem(54),
    paddingTop: rem(18),
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

const InputOutlined = (props: TextInputProps) => {
  const { classes } = useStyles();
  return <TextInput {...props} classNames={classes} />;
};

export default InputOutlined;
