import {
  createStyles,
  Navbar,
  Group,
  Code,
  getStylesRef,
  rem,
  useMantineColorScheme,
  Loader,
} from "@mantine/core";
import {
  IconLogout,
  IconMoonStars,
  IconSun,
  TablerIconsProps,
} from "@tabler/icons-react";
import { useAuth } from "../../../services";
import { routes } from "../../../const";
import { useNavigate } from "react-router-dom";
import { getInitialsFromName } from "../../../helpers";
import { Sizes } from "../../../models";
import { FunctionComponent } from "react";
import { Avatar } from "../../_atoms";

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    transition: "all 200ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
    transition: "all 200ms ease",
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },
  },
}));

const Sidebar = () => {
  const { classes, cx } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const links = routes
    .filter((x) => x.title !== undefined)
    .map((item, i) => {
      const Icon = item.icon as FunctionComponent<TablerIconsProps>;
      return (
        <a
          className={cx(classes.link, {
            [classes.linkActive]:
              item.path &&
              item.path !== "/" &&
              window.location.pathname.startsWith(item.path),
          })}
          href={item.path}
          key={"menu-item-" + i}
          onClick={(event) => {
            event.preventDefault();
            navigate(item.path ?? "/");
          }}
        >
          {item.icon && <Icon className={classes.linkIcon} stroke={2} />}
          <span>{item.title}</span>
        </a>
      );
    });

  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return (
      <Navbar
        position={{ top: 0, left: 0, bottom: 0 }}
        width={{ sm: 300 }}
        p="md"
        pos="fixed"
      >
        <Navbar.Section grow>
          <Group className={classes.header} position="apart">
            <a href="/">
              <img
                src={`/assets/img/logo-with-slogan${
                  colorScheme === "dark" ? "-white" : ""
                }.svg`}
                height={48}
                alt="civitrak"
              />
            </a>
            <Code sx={{ fontWeight: 700 }}>{process.env.REACT_APP_VER}</Code>
          </Group>
          {links}
        </Navbar.Section>

        <Navbar.Section className={classes.footer}>
          <a
            href="/"
            className={cx(classes.link, {
              [classes.linkActive]:
                window.location.pathname === "/user-profile",
            })}
            onClick={(e) => {
              e.preventDefault();
              navigate("/user-profile");
            }}
          >
            {!!user && (
              <Avatar
                initials={getInitialsFromName(
                  `${user?.firstName} ${user?.lastName}`
                )}
                size={Sizes.xs}
                sx={{ marginRight: 12 }}
              />
            )}
            <span>
              {!user ? (
                <Loader size="xs" />
              ) : (
                `${user?.firstName} ${user?.lastName}`
              )}
            </span>
          </a>
          <a
            href="/"
            className={classes.link}
            onClick={(e) => {
              e.preventDefault();
              toggleColorScheme();
            }}
          >
            {colorScheme === "light" ? (
              <IconMoonStars className={classes.linkIcon} stroke={2} />
            ) : (
              <IconSun className={classes.linkIcon} stroke={2} />
            )}
            <span>{colorScheme === "light" ? "Dark Mode" : "Light Mode"}</span>
          </a>
          <a
            href="/"
            className={classes.link}
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          >
            <IconLogout className={classes.linkIcon} stroke={2} />
            <span>Logout</span>
          </a>
        </Navbar.Section>
      </Navbar>
    );
  }

  return <></>;
};

export default Sidebar;
