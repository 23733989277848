import { Box, Card, Sx, Text } from "@mantine/core";
import { JobListDto } from "../../../../models";
import { JobStatus } from "../../../../enums";
import { Date, getJobCodeString } from "../../../../helpers";
import { Badge } from "../../../_atoms";
import { JobColors } from "../../../../const";
import {
  IconBuildingSkyscraper,
  IconCalendar,
  IconClock,
  IconMapPin,
  TablerIconsProps,
} from "@tabler/icons-react";
import { FunctionComponent, useMemo } from "react";

interface JobCardProps {
  model: JobListDto;
  sx?: Sx;
  onClick: Function;
}

const JobCard = ({ model, sx, onClick }: JobCardProps) => {
  const cardRows = useMemo<Array<{ icon: Function; text?: string | null }>>(
    () => [
      {
        icon: IconBuildingSkyscraper,
        text: model.customer,
      },
      {
        icon: IconCalendar,
        text:
          model.start < model.end
            ? `${new Date(model.start).getDateString()} - ${new Date(
                model.end
              ).getDateString()}`
            : new Date(model.start).getDateString(),
      },
      {
        icon: IconClock,
        text: new Date(model.start).getTimeString(),
      },
      {
        icon: IconMapPin,
        text: model.address,
      },
    ],
    [model.address, model.customer, model.end, model.start]
  );

  return (
    <Card
      onClick={() => onClick(`/jobs/${model.id}`)}
      sx={{ cursor: "pointer", ...sx }}
      mb={12}
      className="job-card"
    >
      <Box display="flex" mb={12}>
        <Box className="flex-1">
          <Text transform="uppercase" weight={600} size="sm">
            {getJobCodeString(model.jobCode)}
          </Text>
        </Box>
        {model.jobType && <Badge bg="gray">{model.jobType}</Badge>}
        <Box>
          <Badge bg={JobColors[model.status]}>{JobStatus[model.status]}</Badge>
        </Box>
      </Box>

      {cardRows
        .filter((x) => x.text)
        .map((card, i) => {
          const Icon = card.icon as FunctionComponent<TablerIconsProps>;
          return (
            <Box key={i} display="flex" sx={{ alignItems: "start" }}>
              <Text
                color={JobColors[model.status]}
                mr={6}
                mb={2}
                sx={{ lineHeight: 1 }}
              >
                <Icon size={18} />
              </Text>
              <Text size="sm">{card.text}</Text>
            </Box>
          );
        })}
    </Card>
  );
};

export default JobCard;
